header {
    background-image: linear-gradient(to right bottom, rgb(238, 238, 238) 50%, rgb(255, 255, 255) 50%)
}


.filter-clear {
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1em;
    border-radius: 100%;
    background-color: #c8c8c8;
    color: #f8f9fa;
    text-align: center !important;
}


.select2-results__option {
    padding: 3px 6px;
    // font-size: small;
}


/* body .select2-container .select2-search--inline .select2-search__field {
    font-size: 1rem;
} */

/* tweak default bs font-size to match select2 placeholder font-size */
body .form-control  {
    font-size: inherit;
}


#div_id_categories_filter {
    margin-bottom: 4px;
}

#div_id_year_filter > label {
    display: block; 
    width: 100%;
}

#id_year_filter_1 {
    width: auto;
}

.thesaurus-link {
    margin-bottom: 1rem;
}

.btn-share,
.btn-twitter {
  position: relative;
  box-sizing: border-box;
  padding: 1px 12px 1px 12px;
  // background-color: #1d9bf0;
  background-color: var(--bs-light);
  // color: #fff;
  border-radius: 9999px;
  cursor: pointer;
  white-space: nowrap;

  &:active, 
  &:focus,
  &:hover {
    // background-color: #0c7abf;
    // color: white;
    background-color: var(--bs-primary-bg-subtle);  // --bs-light-bg-subtle)
  }
}
